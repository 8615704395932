import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
	  	{/* <li>
          <Link to="/documentation">Documentation</Link>
        </li>
	  	<li>
			<a href="https://admin.verra.ai">Log In</a>
        </li>
		<li>
          <Link to="/contact">Contact</Link>
        </li>
		<li>
          <Link to="/demo">Request Demo</Link>
        </li> */}
		<li>
			<Link to="/privacy.html">Privacy</Link>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;