import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
	'logo',
    className
  );

  return (
	<div
		{...props}
		className={classes}
	>
		<h1 className="m-0">
		<Link to="/">
			<Image
				src='/assets/images/sphere.png'
				alt="Open"
				width={32}
				height={32} />
			<span>verra</span>
			</Link>
		</h1>
	</div>
  );
}

export default Logo;