import React, { useState } from 'react';
import classNames from 'classnames';
import SectionHeader from '../components/sections/partials/SectionHeader';

let scroll = true;

const Contact = () => {

	const [ formState, setFormState ] = useState(0);

	document.title = 'Verra - Contact Us';
	if( scroll ) window.scrollTo(0, 0);

	const SERVICES_BASE_URL = 'https://admin-services.verra.ai';
	//const SERVICES_BASE_URL = 'https://localhost:3001';

	const innerClasses = classNames(
		'section-inner pt-0 pb-0'
	);

	const splitClasses = classNames(
		'split-wrap',
		'invert-mobile',
		'invert-desktop',
		'align-top'
	);

	const sectionHeader = {
		title: 'Contact Us',
		paragraph: ''
	};

	const handleSubmit = function( e ){
		e.preventDefault();

		scroll = false;
		setFormState(1);

		const params = {
			name: document.getElementById('contact-name').value,
			company: document.getElementById('contact-company').value,
			email: document.getElementById('contact-email').value,
			phone: document.getElementById('contact-phone').value,
			message: document.getElementById('contact-message').value
		};

		const options = {
			method: 'POST',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: ( params != null ) ? JSON.stringify( params ) : null
		};

		fetch( SERVICES_BASE_URL + '/marcom/contact/', options )
			.then(( response ) => {
				if( response.ok ){
					return response.json();
				} else {
					handleFailedResponse( response.statusText );
				}
			})
			.then(( data ) => {
				if( data.success ){
					try{
						handleResponse( data );
					} catch( e ) {
						console.error( e );
					}
				} else {
					handleFailedResponse( data.error ); 
				}
			})
			.catch(( err ) => { 
				// console.dir( err );
				handleFailedResponse( err.message ); 
			}
		);
	};

	const handleResponse = function( response ){
		//console.info( 'handleResponse', response );
		setFormState(2);
	};

	const handleFailedResponse = function( error ){
		//console.info( 'handleFailedResponse', error );
		setFormState(3);
	};

	//const loading = (formState === 1) ? <div style={{ display: 'flex', justifyContent: 'center' }}><Image style={{filter: 'invert(100%)', width: '50px'}} src={require('../assets/images/loading-dots-white.gif')}/></div> : '';

	const btnLoading = (formState === 1) ? ' loading' : '';

	const form = (formState === 0 || formState === 1 || formState === 3) ? <form onSubmit={ handleSubmit.bind( this )}>
		<label htmlFor='contact-name'>* Name</label>
		<input id='contact-name' type='text' required></input>
		<label htmlFor='contact-company'>* Company</label>
		<input id='contact-company' type='text' required></input>
		<label htmlFor='contact-email'>* Email</label>
		<input id='contact-email' type='email' required></input>
		<label htmlFor='contact-phone'>* Phone Number</label>
		<input id='contact-phone' type='tel' required></input>
		<label htmlFor='contact-message'>* Message</label>
		<textarea id='contact-message'></textarea>
		<button className={'button button-primary button-wide-mobile button-sm' + btnLoading}>Contact</button>
	</form> : '';

	const successMsg = (formState === 2) ? <p style={{ textAlign: 'center' }}>Thank you, we will be in touch!</p> : '';
	const errorMsg = (formState === 3) ? <p class='error'>Uh oh, something went wrong, please try again. If the problem persists, reach out to <a href='mailto:sales@verra.ai'>sales@verra.ai</a></p> : '';
	const showContent = (formState === 0 || formState === 1 || formState === 3);

	return (
		<div className='verra-container verra-contact'>
			<div id='bg-vid'>
				<video id='bg-vid-1' playsInline autoPlay muted loop>
					<source src="/assets/video/VerraBackground-5-25FPS-720.mp4" type="video/mp4"/>
				</video>
			</div>
			<div id='verra-content'>
				<section className='subpage-header contact-header'>
					<div className="container">
						<div className={innerClasses}>
							<SectionHeader data={sectionHeader} className="center-content" />
						</div>
					</div>
				</section>
				<section className='subpage-body contact-body'>
					<div className="container">
						<div className={innerClasses}>
							{showContent && 
								<>
									<h4 className="mt-0 mb-8">
										Contact Verra with an comments or questions
									</h4>
									<p className="m-0 text-sm">
										Use the form to send a contact request. We'll be in touch to answer any questions you may have.
									</p>
								</>
							}
							{errorMsg}
							{form}
							{successMsg}

						</div>
					</div>
				</section>
			</div>
		</div>
	);
}

export default Contact;