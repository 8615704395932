import classNames from 'classnames';
import React, { useState } from 'react';
import { SectionTilesProps } from '../../utils/SectionProps';


const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Beta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

	const sectionHeader = {
		title: 'Join the Beta',
		paragraph: ''
	};

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    // 'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    // 'tiles-wrap',
    pushLeft && 'push-left'
  );

	const [ formState, setFormState ] = useState(0);

	const SERVICES_BASE_URL = 'https://admin-services.verra.ai';
	// const SERVICES_BASE_URL = 'https://localhost:3001';

	const handleSubmit = function( e ){
		e.preventDefault();

		// scroll = false;
		setFormState(1);

		const params = {
			name: document.getElementById('contact-name').value,
			company: document.getElementById('contact-company').value,
			email: document.getElementById('contact-email').value,
			message: document.getElementById('contact-message').value
		};

		const options = {
			method: 'POST',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: ( params != null ) ? JSON.stringify( params ) : null
		};

		fetch( SERVICES_BASE_URL + '/marcom/beta/', options )
			.then(( response ) => {
				if( response.ok ){
					return response.json();
				} else {
					handleFailedResponse( response.statusText );
				}
			})
			.then(( data ) => {
				if( data.success ){
					try{
						handleResponse( data );
					} catch( e ) {
						console.error( e );
					}
				} else {
					handleFailedResponse( data.error ); 
				}
			})
			.catch(( err ) => { 
				// console.dir( err );
				handleFailedResponse( err.message ); 
			}
		);
	};

	const handleResponse = function( response ){
		//console.info( 'handleResponse', response );
		setFormState(2);
	};

	const handleFailedResponse = function( error ){
		//console.info( 'handleFailedResponse', error );
		setFormState(3);
	};

const btnLoading = (formState === 1) ? ' loading' : '';

  const form = (formState === 0 || formState === 1 || formState === 3 || formState === 4 ) ? <form onSubmit={ handleSubmit.bind( this )}>
		<p className="m-0 text-sm">
			The Verra beta is invite only. Use the form below to tell us a little bit about yourself and your interest in Verra.
		</p>
		<label htmlFor='contact-name'>* Name</label>
		<input id='contact-name' type='text' required></input>
		<label htmlFor='contact-company'>* Company</label>
		<input id='contact-company' type='text' required></input>
		<label htmlFor='contact-email'>* Email</label>
		<input id='contact-email' type='email' required></input>
		<label htmlFor='contact-message'>* Why would you like to join the Verra beta?</label>
		<textarea id='contact-message'></textarea>
		<button className={'button button-primary button-wide-mobile button-sm' + btnLoading}>Request to Join</button>
	</form> : '';

const successMsg = (formState === 2) ? <p style={{ textAlign: 'center' }}>Thank you, we will be in touch!</p> : '';
const errorMsg = (formState === 3) ? <p class='error'>Uh oh, something went wrong, please try again. If the problem persists, reach out to <a href='mailto:sales@verra.ai'>sales@verra.ai</a></p> : '';

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div id="beta" className="container">
        <div className={innerClasses}>
          
          <div className={tilesClasses}>

		  		<section className='contact-body subpage-body'>
					<div className="container">
						<div className={innerClasses}>
								<>
									<h4 className="mt-0 mb-8">
										Join the Verra Beta
									</h4>
									
								</>
							
							{errorMsg}
							{form}
							{successMsg}

						</div>
					</div>
				</section>

		 	
		  	
          </div>
        </div>
      </div>
    </section>
  );
}

Beta.propTypes = propTypes;
Beta.defaultProps = defaultProps;

export default Beta;