import React from 'react';
import classNames from 'classnames';
import SectionHeader from '../components/sections/partials/SectionHeader';

const Subscribed = () => {

	document.title = 'Verra - Thank You!';

	const innerClasses = classNames(
		'section-inner pt-0 pb-0'
	);

	const sectionHeader = {
		title: 'Thank You',
		paragraph: ''
	};

	// const params = new URLSearchParams( window.location.href );

	return (
		<div className='verra-container verra-contact'>
			<div id='bg-vid'>
				<video id='bg-vid-1' playsInline autoPlay muted loop>
					<source src="/assets/video/VerraBackground-5-25FPS-720.mp4" type="video/mp4"/>
				</video>
			</div>
			<div id='verra-content'>
				<section className='subpage-header'>
					<div className="container">
						<div className={innerClasses}>
							<SectionHeader data={sectionHeader} className="center-content" />
						</div>
					</div>
				</section>
				<section className='subpage-body'>
					<div className="container">
						<div className={innerClasses}>
							<p>Thank you for subscribing!</p>
							<p>You will receive an email with your subscription and account creation details. If you have any questions please reach out to support <a href='mailto:support@verra.ai'>support@verra.ai</a></p>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
}

export default Subscribed;